<template>
<div class="contain">
<div style="font-size: 14px;font-weight: bolder">
  部门名称：
  <el-input size="mini" type="text" v-model="dptname" placeholder="输入部门名称" style="width: 200px"></el-input>
  部门编码：
  <el-input size="mini" type="text" v-model="dptcode" placeholder="输入部门编码" style="width: 200px"></el-input>
  <el-button size="mini" type="primary" style="margin-left:20px;" icon="el-icon-search" @click="search">查询</el-button>
  <el-button type="primary" size="mini" icon="el-icon-refresh" @click="recet">重置</el-button>
</div>
  <el-table
      :data="tableData"
      style="width: 100%;margin: 10px 0;"
      class="table-no-search"
      row-key="id"
      border
      default-expand-all
      :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
      stripe
      :header-cell-style="{color:'#333',fontFamily:'MicrosoftYaHeiUI',fontSize:'14px',fontWeight:900,background:'#f8f8f9'}">
    <el-table-column prop="name" label="部门名称" width="300"></el-table-column>
    <el-table-column prop="weight" label="排序" align="center" width="80"></el-table-column>
    <el-table-column prop="manager" label="部门负责人" align="center"></el-table-column>
    <el-table-column prop="managerPhone" label="负责人联系方式" align="center"></el-table-column>
    <el-table-column prop="code" label="部门编码" align="center"></el-table-column>
    <el-table-column prop="introduce" label="部门介绍" align="center" show-overflow-tooltip></el-table-column>
    <el-table-column prop="generalStatus" label="状态" align="center" width="80">
      <template slot-scope="{row}">
        <el-tag v-if="row.generalStatus==0" type="danger">禁用</el-tag>
        <el-tag v-if="row.generalStatus==1" type="success">启用</el-tag>
      </template>
    </el-table-column>
    <el-table-column prop="createTime" label="创建时间" align="center"></el-table-column>
    <el-table-column label="操作" align="left">
      <template slot-scope="{row}">
        <el-button type="text" @click="updataUser(row)" size="small">编辑</el-button>
        <el-button type="text" @click="adddataUser(row)" size="small" style="color:#67C23A">新增</el-button>
        <el-button v-if="row.code!='sgd'&&row.id!=1&&row.id!=8&&row.code!='hfrq001' && row.code!='aqyyb'" type="text" @click="del(row)" size="small"  style="color:red">删除</el-button>
      </template>
    </el-table-column>
  </el-table>
  <el-dialog
      :title="title==1?'添加部门信息':'编辑部门信息'"
      :visible.sync="dialogVisible"
      width="35%"
      :close-on-click-modal="false"
      @close="clearnF('form')">
    <el-form ref="form" :model="form" label-width="100px" :rules="rule">
      <div style="display: flex">
        <el-form-item label="部门名称：" prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="排序：" prop="sort">
          <el-input v-model="form.sort" type="number" :min="1"></el-input>
        </el-form-item>
      </div>
      <div style="display: flex">
        <el-form-item label="负责人：" prop="manager">
          <el-input v-model="form.manager"></el-input>
        </el-form-item>
        <el-form-item label="联系电话：" prop="managerPhone">
          <el-input v-model="form.managerPhone" type="text" ></el-input>
        </el-form-item>
      </div>
      <div style="display: flex">
        <el-form-item label="部门编码：" prop="code">
          <el-input v-model="form.code" type="text" v-if="form.code=='sgd' || form.code=='hfrq001'|| form.code=='aqyyb'"  disabled></el-input>
          <el-input v-model="form.code" type="text" v-if="form.code!='sgd'&&form.code!='hfrq001'&&form.code!='aqyyb'" ></el-input>
        </el-form-item>
        <el-form-item label="部门状态：">
          <el-radio-group v-model="form.generalStatus">
            <el-radio :label="1">启用</el-radio>
            <el-radio :label="0">禁用</el-radio>
          </el-radio-group>
        </el-form-item>
      </div>
      <el-form-item label="部门介绍：" prop="introduce">
        <el-input type="textarea" v-model="form.introduce"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button v-if="title==1" type="primary" @click="submitfrom('form')">确 定</el-button>
    <el-button v-else type="primary" @click="updatasubmitfrom('form')">确 定</el-button>
  </span>
  </el-dialog>
</div>
</template>

<script>
import {selectSysDeptTreeList, saveDepart, updateDepart, delDepart,selectDepart} from '../../RequestPort/user/user'
export default {
  name: "department",
  data(){
    return{
      fileList:[],
      title:'1',
      tableData: [],
      dialogVisible:false,
      dptname:'',
      dptcode:'',
      dptstatus:'',
      form:{
        generalStatus:1,
        sort:'',
        name:'',
        manager:'',
        managerPhone:'',
        code:'',
        introduce:''
      },
      rule:{
        name:[{ required: true, message: '请输入部门名称', trigger: 'blur' }],
        code:[{ required: true, message: '请输入部门编码', trigger: 'blur' }],
        sort:[{ required: true, message: '请输入排序', trigger: 'blur' }],
        manager:[{ required: true, message: '请输入负责人', trigger: 'blur' }],
        managerPhone:[{ required: true, message: '请输入负责人联系电话', trigger: 'blur' }],
        introduce:[{ required: true, message: '请输入介绍', trigger: 'blur' }],
      }
    }
  },
  mounted() {
    this.loadList()
  },
  methods:{
    del(row){
      this.$confirm(`是否删除部门---[ ${row.name} ]?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal:false,
        type: 'warning'
      }).then(() => {
        delDepart({id:row.id}).then((e)=>{
          if(e.code===200){
            this.$message.success(e.msg)
            this.loadList()
          }else{
            this.$message.error(e.msg)
          }
        })
      })
    },
    loadList(){
      selectSysDeptTreeList({deptListShow:'y'}).then((e)=>{
        this.tableData = e.data
      })
    },
    submitfrom(form) {
      var myreg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
      if (!myreg.test(this.form.managerPhone)) {
        return this.$message.error('手机号格式错误')
      } else {
        this.$refs[form].validate((valid) => {
          if (!valid) return false
          saveDepart(this.form).then((res) => {
            if (res.code === 200) {
              this.$message.success(res.msg)
              this.dialogVisible = false
              this.loadList()
            } else {
              this.$message.error(res.msg)
            }
          })
        })
      }
    },
    updatasubmitfrom(form){
      var myreg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
      if (!myreg.test(this.form.managerPhone)) {
        return this.$message.error('手机号格式错误')
      } else {
        this.$refs[form].validate((valid) => {
          if (!valid) return false
          updateDepart(this.form).then((res) => {
            if (res.code === 200) {
              this.$message.success(res.msg)
              this.dialogVisible = false
              this.loadList()
            } else {
              this.$message.error(res.msg)
            }
          })
        })
      }
    },
    adddataUser(row){
      this.title = 1
      this.form.pid = row.id
      this.dialogVisible = true
    },
    updataUser(row){
      let obj = JSON.parse(JSON.stringify(row))
      this.form.pid = obj.parentId
      this.form.id = obj.id
      this.form.sort = obj.weight
      this.form.name = obj.name
      this.form.manager = obj.manager
      this.form.managerPhone = obj.managerPhone
      this.form.generalStatus = obj.generalStatus
      this.form.code = obj.code
      this.form.introduce = obj.introduce
      this.dialogVisible = true
      this.title = 0
    },
    search(){
      let obj = {
        current:1,
        size:100,
        name:this.dptname,
        code:this.dptcode,
      }
      selectDepart(obj).then((e)=>{
        this.tableData = e.data
      })
    },
    recet(){
      this.dptname=''
      this.dptcode=''
      selectSysDeptTreeList({deptListShow:'y'}).then((e)=>{
        this.tableData = e.data
      })
    },
    clearnF(form){
      this.form = {
        generalStatus:1,
        sort:'',
        name:'',
        manager:'',
        managerPhone:'',
        code:'',
        introduce:''
      }
      this.$refs[form].resetFields()
    },
  }
}
</script>

<style scoped>

</style>
